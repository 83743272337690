import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import TableRowStyled from './styled'

export const TableRow = props => {
  const handleRowClicked = useCallback(() => {
    if (props.onRowClick) props.onRowClick(props.data)
  }, [props.data])

  const preparedClassName = useMemo(() => {
    let className = 'reuse-table-row'
    className += ` ${props.className}`
    if (props.onRowClick) {
      className += ' clickable'
    }
    return className
  }, [props.onRowClick])

  return (
    <TableRowStyled onClick={handleRowClicked} className={preparedClassName}>
      {props.children}
    </TableRowStyled>
  )
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  onRowClick: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default memo(TableRow)
