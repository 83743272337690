import styled from 'styled-components'

export default styled.tr`
  transition: 0.5s;
  &.clickable {
    &:hover {
      background-color: #3d3d3d;
      cursor: pointer;
    }
  }
  td {
    padding: 12px;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
